<template>
	<div class="newScheme">
		<router-view v-if="isRouterAlive"></router-view>
		<h4>机会点设计方案</h4>
		<div class="bottom">
			<div class="stuinfo">
				<el-row :gutter="100">
					<el-col :span="12">
						<span>学生姓名：</span>
						<el-input disabled v-model="stuinfo.studentName"></el-input>
					</el-col>
					<el-col :span="12">
						<span>高中院校：</span>
						<el-input disabled v-model="stuinfo.schoolName"></el-input>
					</el-col>
				</el-row>
				<el-row :gutter="100">
					<el-col :span="12">
						<span>性别：</span>
						<el-input disabled v-model="this.sys_sex"></el-input>
					</el-col>
					<el-col :span="12">
						<span>已选科类：</span>
						<el-input disabled v-model="stuinfo.syS_SubjectGroup"></el-input>
					</el-col>
				</el-row>
				<el-row :gutter="100">
					<el-col :span="12">
						<span>所属民族：</span>
						<el-input disabled v-model="this.sys_nation"> </el-input>
					</el-col>
					<el-col :span="12">
						<span>户籍所在地：</span>
						<el-input disabled v-model="stuinfo.province"></el-input>
					</el-col>
				</el-row>
				<el-row :gutter="100">
					<el-col :span="12">
						<span>预估分数：</span>
						<el-input disabled v-model="stuinfo.expectScores"></el-input>
					</el-col>
					<el-col :span="12">
						<span>学科特长：</span>
						<el-input v-model="specialty"></el-input>
					</el-col>
				</el-row>
				<el-row :gutter="100">
					<el-col :span="12">
						<span>本方案分数段：</span>
						<el-input v-model="gradeSegment"></el-input>
					</el-col>
					<el-col :span="12"><span>填表时间：</span>
						<el-input disabled v-model="time"></el-input>
					</el-col>
				</el-row>
				<el-row :gutter="100">
					<el-col :span="12">
						<span>家长和同学意向专业排序：</span>
						<el-input disabled type="textarea" v-model="stuinfo.intendSubejct"></el-input>
					</el-col>
					<el-col :span="12">
						<span>家长和同学意向地区排序：</span>
						<el-input disabled type="textarea" v-model="intentionRegion"></el-input>
					</el-col>
				</el-row>
			</div>
			<div class="rec">
				<h3>院校机会点推荐</h3>
				<div class="recContainer">
					<el-row :gutter="50" v-for="(item, index) in rec" :key="index">
						<el-col :span="6"><span>机会点类型</span>
							<!-- <el-select v-model="type"></el-select> -->
							<AuthSelect :placeholder="'请选择机会点类型'" :isEdit="true" :data-source="'SYS_ChanceType'"
								v-model="item.syS_ChanceType" size="small" @change="search(index)">
							</AuthSelect>
						</el-col>
						<el-col :span="6"><span>院校名称</span>
							<el-select v-model="item.shcoolList.label" :placeholder="'请选择名校名称'" size="small"
								@change="shcoolSearch(item.shcoolList.label, index)">
								<el-option v-for="(items, indexs) in item.shcoolList" :key="indexs" :label="items.label"
									:value="items.label">
								</el-option>
							</el-select>
						</el-col>
						<el-col :span="5"><span>院校层次</span>
							<el-input v-model="item.arrangement" readonly size="small"></el-input>
						</el-col>
						<el-col :span="5"><span>招生专业</span>
							<el-input v-model="item.enrollmentMajor" size="small"></el-input>
						</el-col>
						<i :span="2" class="el-icon-close" @click="removeRow(index)"></i>
					</el-row>
					<el-button type="primary" plain size="small" @click="addRow">添加一行</el-button>
				</div>
			</div>
			<div class="btns">
				<el-button plain size="small" @click="$router.back()">返回</el-button>
				<el-button plain size="small">预览</el-button>
				<el-button plain size="small" @click="designShow = true">方案设计思路</el-button>
				<el-button plain size="small" @click="getReclist()" v-if="this.addState == false">暂存</el-button>
				<el-button plain size="small" @click="upadteReclist()" v-else>修改</el-button>
				<!-- <el-button type="primary" size="small" @click="sendChanceplans">发送</el-button> -->
			</div>
		</div>
		<DesignIdeas :designShow="designShow" @closeDesign="closeDesign"></DesignIdeas>
	</div>
</template>

<script>
	import DesignIdeas from "../../components/designIdeas.vue";
	import * as categorys from "@/api/categorys";
	import * as chanceplans from "@/api/chanceplans";
	import * as schools from "@/api/schools";
	import AuthSelect from "@/components/AuthSelect";
	import {
		Message
	} from "element-ui";
	export default {
		name: "schools",
		components: {
			DesignIdeas,
			AuthSelect,
		},
		data() {
			return {
				gategoryList: [],
				specialty: "",
				gradeSegment: "",
				time: "",
				addState: false,
				subjectgroup: "",
				intentionMajor: "",
				intentionRegion: "",
				sys_sex: "",
				sys_nation: "",
				designShow: false,
				diC_KeySchool: [],
				schoolListSelect: [], //学校名称
				rec: [{
					id: "",
					syS_ChanceType: "", //机会点类型
					schoolName: "", //学校名称
					schoolCode: "", //学校代码
					syS_Level: "", //学校层级
					arrangement: "",
					enrollmentMajor: "",
					shcoolList: [],
				}, ],
				newrec: {
					id: "",
					syS_ChanceType: "", //机会点类型
					schoolName: "", //学校名称
					schoolCode: "", //学校代码
					syS_Level: "", //学校层级
					arrangement: "",
					enrollmentMajor: "",
				},
				isRouterAlive: true,
				getSchemedId: "",
				stuinfo: {}
				// listQuery: {
				// 	syS_ChanceType: "", //机会点类型
				// 	schoolName: "", //学校名称
				// 	schoolCode: "", //学校代码
				// 	syS_Level: "", //学校层级
				// },
			};
		},
		created() {
			this.getGategoryList();
			this.stuinfo = JSON.parse(window.localStorage.getItem("stuInfo"));
			console.log(this.stuinfo);
			if (this.stuinfo.syS_Sex == "1") {
				this.sys_sex = "男";
			} else {
				this.sys_sex = "女";
			}

			setTimeout(() => {
				console.log("all" + this.stuinfo.syS_SubjectGroup);
				if (this.stuinfo.syS_SubjectGroup == "1") {
					this.subjectgroup = "文科";
				} else {
					this.subjectgroup = "理科";
				}
				this.showNation();
			}, 1000);
		},
		activated() {
			this.getGategoryList();
			this.stuInfo = JSON.parse(window.localStorage.getItem("stuInfo"));
			console.log(JSON.parse(window.localStorage.getItem("stuInfo")));
			if (this.stuInfo.syS_Sex == "1") {
				this.sys_sex = "男";
			} else {
				this.sys_sex = "女";
			}

			setTimeout(() => {
				console.log("all" + this.stuinfo.syS_SubjectGroup);
				if (this.stuinfo.syS_SubjectGroup == "1") {
					this.subjectgroup = "文科";
				} else {
					this.subjectgroup = "理科";
				}
				this.showNation();
			}, 1000);
			//显示民族,显示之后判断id是否为方案库传过来的
		},
		provide() {
			return {
				reload: this.reload,
			};
		},
		methods: {
			reload() {
				this.isRouterAlive = true;
				this.$nextTick(function() {
					this.isRouterAlive = true;
				});
			},
			showNation() {
				console.log(this.stuinfo.syS_Nation);
				this.sys_nation = this.getGategoryName(
					JSON.parse(window.localStorage.getItem("stuInfo")).syS_Nation,
					"SYS_NATION"
				);

				this.getSchemedId = this.$route.query.id;
				console.log("schemeId:" + this.getSchemedId);
				if (this.getSchemedId != undefined) {
					this.addState = true;
					this.getUpdatelist(this.getSchemedId);
				} else {
					this.addState = false;
				}
			},
			addRow() {
				this.rec.push({
					id: "",
					syS_ChanceType: "", //机会点类型
					schoolName: "", //学校名称
					schoolCode: "", //学校代码
					syS_Level: "", //学校层级
					arrangement: "",
					shcoolList: [],
				});
				Message({
					message: "添加成功",
					type: "success",
					duration: 5 * 1000,
				});
			},
			restRow(index, val) {
				this.rec[index].syS_ChanceType = "" + val + "";
				(this.rec[index].schoolName = ""), //学校名称
				(this.rec[index].schoolCode = ""); //学校代码
				this.rec[index].syS_Level = ""; //学校层级
				this.rec[index].arrangement = "";
				this.rec[index].shcoolList = [];
			},
			removeRow(index) {
				this.rec.splice(index, 1);
				console.log(index);
				// this.schoolListSelect.splice(0);
				// this.schoolListSelect.label = null;
				// console.log(this.schoolListSelect);
				Message({
					message: "删除成功",
					type: "success",
					duration: 5 * 1000,
				});
			},
			closeDesign(val) {
				this.designShow = val;
			},
			showInputVal() {},
			search(index) {
				//const shelf = this;
				this.list = [];
				let test = {};
				this.listLoading = true;
				// console.log(this.rec[index]);
				this.rec[index].schoolName = ""; //学校名称
				this.rec[index].schoolCode = ""; //学校代码
				this.rec[index].syS_Level = ""; //学校层级
				this.rec[index].shcoolList = [];
				this.rec[index].arrangement = "";
				// console.log(this.rec[index]);
				//console.log(this.rec[index].shcoolList);
				schools.getList(this.rec[index]).then((response) => {
					let list1 = response.data;
					for (let i = 0; i < list1.length; i++) {
						let o = list1[i];
						console.log(o);
						o.diC_KeySchool = list1[i].diC_KeySchool.split(",");
						test = {
							label: o.schoolName,
							val: o.schoolCode,
							syS_Level: o.syS_Level,
						};
						//this.rec[index].syS_Level=o.syS_Level;
						//this.rec[index].schoolCode = o.schoolCode;
						this.rec[index].shcoolList.push(test);
					}
					//console.log(this.rec[index].shcoolList);
				});
			},
			getGategoryList() {
				let listQuery = {
					page: 1,
					limit: 9999,
				};

				categorys.getList(listQuery).then((res) => {
					this.gategoryList = res.data;
				});
			},
			shcoolSearch(val, index) {
				this.rec[index].schoolName = val;
				let level = 0;
				this.rec[index].syS_Level = 0;
				for (let i = 0; i < this.rec[index].shcoolList.length; i++) {
					var getVal = this.rec[index].shcoolList[i].label;
					console.log(getVal);
					if (getVal == val) {
						level = this.rec[index].shcoolList[i].syS_Level;
					}
				}
				let theschoolindex = this.rec[index].shcoolList.findIndex((item) => {
					return item.label == val;
				});
				this.rec[index].schoolCode =
					this.rec[index].shcoolList[theschoolindex].val;
				console.log(this.rec[index].schoolCode);
				this.rec[index].syS_Level = level;
				this.rec[index].arrangement = "";
				var name = this.getGategoryName("" + level + "", "SYS_Level");
				this.rec[index].arrangement = name;
			},
			getGategoryName(val, columnName) {
				var dicobject = this.gategoryList.find(
					(t) =>
					t.typeId.toUpperCase() == columnName.toUpperCase() && t.dtValue == val
				);
				if (!dicobject) {
					return "";
				} else {
					//console.log(dicobject.name);
					return dicobject.name;
				}
			},
			addRec() {
				this.rec.push({
					id: "",
					syS_ChanceType: "", //机会点类型
					schoolName: "", //学校名称
					schoolCode: "", //学校代码
					syS_Level: "", //学校层级
					arrangement: "",
					shcoolList: [],
				});
			},
			//显示编辑ID列表
			getUpdatelist(id) {
				chanceplans
					.getChancePlan(id)
					.then((res) => {
						this.rec = [];
						let list1 = res.result.chancePlanDetails;
						console.log(res);
						console.log(this.rec);
						for (let i = 0; i < list1.length; i++) {
							if (i != 0) {
								this.addRec();
								this.addUpdateRows(i, list1[i]);
							} else if (i == 0 && this.rec.length == 0) {
								this.addRec();
								this.addUpdateRows(i, list1[i]);
							} else {
								this.addUpdateRows(i, list1[i]);
							}
						}
					})
					.catch((res) => {
						console.log(res);
					});
			},
			//显示下方院校机会点推荐数据
			addUpdateRows(i, list1) {
				console.log(this.rec[i]);
				this.rec[i].id = list1.id;
				this.rec[i].syS_ChanceType = list1.syS_ChanceType;
				setTimeout(() => {
					this.search(i)
				}, 500);
				//this.search(i);
				setTimeout(() => {
					// console.log(this.rec[i].shcoolList);
					let getSchoollist = JSON.parse(JSON.stringify(this.rec[i].shcoolList));
					console.log(getSchoollist);
					let index = getSchoollist.findIndex((item) => {
						return item.val == list1.schoolCode;
					});
					console.log(getSchoollist[index]);
					this.rec[i].schoolCode = getSchoollist[index].val;
					this.rec[i].arrangement = this.getGategoryName(
						list1.syS_Level,
						"SYS_Level"
					);
					this.rec[i].syS_Level = list1.syS_Level;
					console.log(this.rec[i].shcoolList[index]);
					this.rec[i].shcoolList.label = getSchoollist[index].label;
					//this.rec[i].enrollmentMajor = list1.subject;
					this.$set(this.rec[i], "enrollmentMajor", list1.subject); //防止el-input赋值后不可进行对应编辑
				}, 1000);
			},
			//暂存提交
			getReclist() {
				console.log("getReclist");
				let s = 0;
				var newreclist = [];
				var tgmobeilTel = JSON.parse(localStorage.getItem("stuInfo")).mobileTel;
				console.log(this.rec);
				while (s < this.rec.length) {
					console.log(this.rec[s]);
					let index = this.rec[s].shcoolList.findIndex((item) => {
						return (item.label = this.rec[s].schoolName);
					});
					console.log(this.rec[s].shcoolList[index]);
					this.newrec.syS_ChanceType = this.rec[s].syS_ChanceType;
					this.newrec.schoolName = this.rec[s].schoolName;
					this.newrec.schoolCode = this.rec[s].schoolCode;
					this.newrec.enrollmentMajor = this.rec[s].enrollmentMajor;
					this.newrec.syS_Level = this.rec[s].syS_Level;
					newreclist.push({
						id: "",
						SYS_ChanceType: "" + this.newrec.syS_ChanceType + "",
						SchoolCode: "" + this.newrec.schoolCode + "",
						SYS_Level: "" + this.newrec.syS_Level + "",
						Subject: "" + this.newrec.enrollmentMajor + "",
					});
					s += 1;
				}

				console.log(newreclist);
				var datas = {
					Id: "",
					MobileTel: tgmobeilTel,
					ChancePlanDetails: newreclist,
				};
				console.log(datas);
				// lisst.push(datas);
				//console.log(lisst);
				chanceplans.addNew(datas).then((res) => {
					//this.list.unshift();
					console.log(res);
					if (res.code == 200) {
						//this.rec = [];
						//this.reload;
						this.$notify({
							title: "成功",
							message: "创建成功",
							type: "success",
							duration: 2000,
						});
						this.getNewSchemeId();
					} else {
						this.$message(res.message);
					}
				});
			},
			//修改
			upadteReclist() {
				console.log("upadteReclist");
				let s = 0;
				var newreclist = [];
				var tgmobeilTel = JSON.parse(localStorage.getItem("stuInfo")).mobileTel;
				console.log(this.rec);
				while (s < this.rec.length) {
					console.log(this.rec[s]);
					let index = this.rec[s].shcoolList.findIndex((item) => {
						return (item.label = this.rec[s].schoolName);
					});
					console.log(this.rec[s].shcoolList[index]);
					this.newrec.id = this.rec[s].id;
					this.newrec.syS_ChanceType = this.rec[s].syS_ChanceType;
					this.newrec.schoolName = this.rec[s].schoolName;
					this.newrec.schoolCode = this.rec[s].schoolCode;
					this.newrec.enrollmentMajor = this.rec[s].enrollmentMajor;
					this.newrec.syS_Level = this.rec[s].syS_Level;
					newreclist.push({
						id: "" + this.newrec.id + "",
						SYS_ChanceType: "" + this.newrec.syS_ChanceType + "",
						SchoolCode: "" + this.newrec.schoolCode + "",
						SYS_Level: "" + this.newrec.syS_Level + "",
						Subject: "" + this.newrec.enrollmentMajor + "",
					});
					s += 1;
				}

				console.log(newreclist);
				var datas = {
					Id: this.getSchemedId,
					MobileTel: tgmobeilTel,
					ChancePlanDetails: newreclist,
				};
				console.log(datas);
				chanceplans.updateNew(datas).then((res) => {
					console.log(res);
					if (res.code == 200) {
						this.rec = [];
						this.addState = false;
						this.$notify({
							title: "成功",
							message: "修改成功",
							type: "success",
							duration: 2000,
						});
					} else {
						this.$message(res.message);
					}
				});
			},
			//获取最新方案ID
			getNewSchemeId() {
				this.temp = {
					page: 1,
					limit: 99
				};
				this.temp.mobileTel = JSON.parse(localStorage.getItem("stuInfo")).mobileTel;
				console.log(this.temp);
				chanceplans.getList(this.temp).then((res) => {
					console.log(res);
					let list1 = res.data;
					this.getSchemedId = list1[list1.length - 1].id;
					console.log(this.getSchemedId);
				}).catch((res) => {
					console.log(res);
				})
			},
			//发送
			sendChanceplans() {
				if (this.getSchemedId == undefined || this.getSchemedId == "") {
					this.$message.error("发送操作失败,请先进行暂存或者修改！")
					return;
				} else {
					chanceplans.send(this.getSchemedId).then((res) => {
						if (res.code == 200) {
							this.$notify({
								title: "成功",
								message: "发送成功",
								type: "success",
								duration: 2000,
							});
							this.rec = [];
							this.getSchemedId = "";
						} else {
							this.$message.error(res.message);
						}
					}).catch((res) => {
						console.log(res);
					})
				}

			}
		},
		computed: {
			// stuinfo() {
			// 	//console.log(this.$store.state.stu.stuInfo);
			// 	return this.$store.state.stu.stuInfo;
			// },
		},
	};
</script>

<style lang="scss" scoped="">
	.newScheme {
		margin: 15px;
		background-color: white;
		height: calc(100% - 30px);

		h4 {
			padding: 10px 20px;
			margin: 0;
			border-bottom: 1px solid #eee;
		}

		.bottom {
			height: calc(100% - 50px);

			.stuinfo {
				padding: 20px 10px;
				text-align: right;
				overflow: hidden;

				.el-row {
					margin-bottom: 40px;

					.el-col {
						display: flex;
						padding-right: 0;

						span {
							flex: 1;
							padding: 8px 0;
						}

						.el-input,
						.el-textarea {
							width: 60%;
						}
					}
				}
			}

			.rec {
				text-align: center;
				margin: 15px;

				h3 {
					margin-top: 0;
				}

				.recContainer {
					border: 1px dashed #ddd;
					padding: 15px;

					.el-row {
						margin: 5px 0;

						.el-col {
							display: flex;
							padding-right: 0;

							span {
								flex: 1;
								padding: 8px 0;
							}

							.el-input,
							.el-textarea {
								width: 60%;
							}
						}
					}

					.el-button {
						margin-top: 15px;
					}

					i {
						padding: 5px 0;
						font-size: 24px;
						cursor: pointer;
					}

					i:hover {
						color: red;
					}
				}
			}

			.btns {
				text-align: center;
			}
		}
	}
</style>
